import { SvgIcon } from "@mui/material";
import React from "react";

function GplanIconWhite() {
  return (
    <SvgIcon fontSize="large">
      <svg
            
            viewBox="0 0 49 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Frame 10">
              <g id="Group">
                <path
                  id="Vector"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.8226 5.36267L42.0918 15.3333V35.2742L24.8226 45.2451L7.55273 35.2742V15.3333L24.8226 5.36267Z"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-miterlimit="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <g id="Group_2">
                <path
                  id="Vector_2"
                  d="M14.8086 31.0976L31.6215 41.0489"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-miterlimit="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <g id="Group_3">
                <path
                  id="Vector_3"
                  d="M34.8906 31.0803L17.7478 41.0664"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-miterlimit="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <g id="Group_4">
                <path
                  id="Vector_4"
                  d="M24.8202 5.3623V25.3035M24.8202 25.3035L7.75977 35.2079M24.8202 25.3035L42.0901 35.1385"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-miterlimit="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <g id="Group_5">
                <path
                  id="Vector_5"
                  d="M14.7031 11.6611L14.7326 23.5527L14.7253 30.9879"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-miterlimit="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <g id="Group_6">
                <path
                  id="Vector_6"
                  d="M24.9277 5.8736L34.9 31.0977"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-miterlimit="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </g>
          </svg>
    </SvgIcon>
  );
}

export default GplanIconWhite;
